<template>
  <div class="warning-list-page">
    <div class="box center search-wrapper">
      <div class="box center search">
        <el-form :model="operateForm" :inline="true" ref="operateForm" label-width="85px" class="my-form">
          <el-form-item label="选择时间" prop="startTime">
            <el-date-picker type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="operateForm.startTime"> </el-date-picker>
          </el-form-item>
          <span class="line">-</span>
          <el-form-item prop="endTime">
            <el-date-picker type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="operateForm.endTime"> </el-date-picker>
          </el-form-item>
          <el-form-item label="超限类型" prop="warnType">
            <el-select v-model="operateForm.warnType" placeholder="请选择" class="my-type">
              <el-option v-for="item in warningTypeOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="超限等级" prop="level">
            <el-select v-model="operateForm.level" placeholder="请选择">
              <el-option v-for="item in warningLevelOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="处理状态" prop="status">
            <el-select v-model="operateForm.status" placeholder="请选择">
              <el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="operateForm.code" placeholder="请输入测点编号"></el-input>
          </el-form-item>
          <el-button @click="handleSearch" style="margin-left: 10px">查询</el-button>
          <el-button class="reset-btn" @click="handleReset">重置</el-button>
        </el-form>
      </div>
      <div class="btn-right-wrapper">
        <el-button @click="showDataoverrun">数据分析报警</el-button>
        <el-button @click="handleMultiple" :disabled="!selectTables.length" :class="!myOption ? 'disable_icon' : ''">批量处理</el-button>
      </div>
    </div>
    <div class="table-wrapper">
      <div class="tbopt" v-show="isShowHeaderList">
        <el-checkbox-group v-model="checkedTbHeader">
          <div v-for="item in tableHeaderData" :key="item.name" class="tbopt-item">
            <el-checkbox :label="item.name" :disabled="item.disabled">{{ item.name }}</el-checkbox>
          </div>
        </el-checkbox-group>
        <div class="btn-click">
          <el-button @click="handleConfirm">确定</el-button>
          <el-button class="cancel-btn" @click="isShowHeaderList = false">取消</el-button>
        </div>
      </div>
      <template v-if="tableData.length">
        <el-table
          :row-class-name="tableRowClassName"
          :header-cell-style="{ background: '#04153F', color: '#fff' }"
          @header-click="headerClick"
          @selection-change="handleSelectionChange"
          ref="myTable"
          :data="tableData"
          :height="tableHeight"
        >
          <el-table-column type="selection" width="55" :selectable="checkIsSelectable"></el-table-column>
          <el-table-column v-for="(item, index) in headerDetails" :key="index" :label="item.name" :prop="item.value" :width="item.value == 'timeO' ? 210 : ''" :render-header="renderHeader">
            <template slot-scope="scope">
              <!-- 开始时间 -->
              <div v-if="item.value == 'timeS'">{{ scope.row.timeS }}</div>
              <!-- 结束时间 -->
              <div v-if="item.value == 'timeE'">
                {{ scope.row.flag == 1 ? "报警中..." : scope.row.timeE }}
              </div>
              <!-- 报警类别 -->
              <div v-if="item.value == 'warnType'">
                {{ formatWarnType(scope.row.warnType) }}
              </div>
              <!-- 监测内容 -->
              <div v-if="item.value == 'content'">
                {{ scope.row.content }}
              </div>
              <!-- 预警级别 -->
              <div v-if="item.value == 'level'">
                <span :class="scope.row.level == 1 ? 'blue-level' : scope.row.level == 2 ? 'yellow-level' : 'red-level'">
                  {{ formatLevel(scope.row.level) }}
                </span>
              </div>
              <!-- 预警类型 -->
              <div v-if="item.value == 'typeRel'">
                {{ formatterType(scope.row.typeRel) || "/" }}
              </div>
              <!-- 监测指标 -->
              <div v-if="item.value == 'typeName'">
                {{ scope.row.typeName || "/" }}
              </div>
              <!-- 测点编号 -->
              <div v-if="item.value == 'code'">
                {{ scope.row.code || "/" }}
              </div>
              <!-- 检查建议 -->
              <div v-if="item.value == 'suggest'">
                {{ scope.row.suggest || "/" }}
              </div>
              <!-- 最大报警值 -->
              <div v-if="item.value == 'maxValue'">
                {{ scope.row.maxValue || "/" }}
              </div>
              <!-- 阈值区间 -->
              <div v-if="item.value == 'thresholdValue'">
                {{ scope.row.thresholdValue || "/" }}
              </div>
              <!-- 处理状态 -->
              <div v-if="item.value == 'status'">
                <span :class="scope.row.status == '1' ? 'green-level' : 'red-level'">
                  {{ formatStauts(scope.row.status) }}
                </span>
              </div>

              <!-- 操作 -->
              <div v-if="item.value == 'operate'">
                <span class="see-btn" v-show="scope.row.status == 1" @click="handleSee(scope.row)" :class="!myOption ? 'disable_icon' : ''">查看</span>
                <span class="handle-btn" v-show="scope.row.status == 0" @click="handleOperate(scope.row)" :class="!myOption ? 'disable_icon' : ''">处理</span>
                <span class="delete-btn" v-show="isAdmin == 1" @click="handleDelete(scope.row)">删除</span>
              </div>
            </template>
            <!-- <template slot-scope="scope">
                        <div v-if="item.value == 'level'">
                            <span :class="scope.row.level == 3 ? 'blue-level' : scope.row.level == 2 ? 'yellow-level' : 'red-level'">
                                {{formatLevel(scope.row.level)}}
                            </span>
                        </div>
                        <div v-else-if="item.value == 'typeRel'">
                            {{formatterType(scope.row.typeRel)}}
                        </div>
                        <div v-else-if="item.value == 'status'">
                            <span :class="scope.row.status == '1' ? 'green-level' : 'red-level'">
                                {{formatStauts(scope.row.status)}}
                            </span>
                        </div>
                        <div v-else-if="item.value == 'operate'">
                            <span class="see-btn" v-show="scope.row.status == 1" @click="handleSee(scope.row)">查看</span>
                            <span class="handle-btn" v-show="scope.row.status == 0" @click="handleOperate(scope.row)">处理</span>
                            <span class="delete-btn" v-show="isAdmin == 1" @click="handleDelete(scope.row)">删除</span>
                        </div>
                        <div v-else>{{scope.row[item.value]}}</div>
                    </template> -->
          </el-table-column>
          <!-- <el-table-column prop="timeO" label="预警时间" width="220"></el-table-column>
                <el-table-column prop="typeName" label="预警类型"></el-table-column>
                <el-table-column prop="level" label="预警级别">
                    <template slot-scope="scope">
                        <span :class="scope.row.level == 3 ? 'blue-level' : scope.row.level == 2 ? 'yellow-level' : 'red-level'">
                            {{formatLevel(scope.row.level)}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="typeRel" label="监测类型" :formatter="formatterType"></el-table-column>
                <el-table-column prop="quotaName" label="监测指标"></el-table-column>
                <el-table-column prop="code" label="测点编号"></el-table-column>
                <el-table-column prop="value" label="监测值"></el-table-column>
                <el-table-column prop="threshold" label="阈值区间"></el-table-column>
                <el-table-column prop="status" label="处理状态">
                    <template slot-scope="scope">
                        <span :class="scope.row.status == '1' ? 'green-level' : 'red-level'">
                            {{formatStauts(scope.row.status)}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <span class="see-btn" v-show="scope.row.status == 1" @click="handleSee(scope.row)">查看</span>
                        <span class="handle-btn" v-show="scope.row.status == 0" @click="handleOperate(scope.row)">处理</span>
                        <span class="delete-btn" v-show="isAdmin == 1" @click="handleDelete(scope.row)">删除</span>
                    </template>
                </el-table-column> -->
          <el-table-column prop="opt" :render-header="renderHeader" width="50px"></el-table-column>
        </el-table>
      </template>

      <common-pagination v-show="tableData.length > 0" class="pageMain" :pageSize="pageSize" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </div>
    <div class="noDataMain" v-if="!tableData.length">
      <img src="../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>
    <!-- 查看、处理、批量处理弹窗 -->
    <el-dialog
      :title="operateType == 'see' ? '报警查看' : operateType == 'handle' ? '报警处理' : '预警批量处理'"
      :visible.sync="dialogVisible"
      class="my-dialog"
      top="15vh"
      :before-close="handleDialogClose"
    >
      <div class="operate-wrapper">
        <div class="info-wrapper">
          <div class="title">
            <el-divider class="my-shu-divider" direction="vertical"></el-divider>
            <span class="title-name">超限预警信息</span>
          </div>
          <div class="info-table" v-show="operateType != 'multiple'">
            <div class="info-item">
              <div class="info-item-title">测点编号</div>
              <div class="info-item-content">{{ detailsData.code }}</div>
            </div>
            <div class="info-item">
              <div class="info-item-title">开始时间</div>
              <div class="info-item-content">{{ detailsData.timeS }}</div>
            </div>
            <div class="info-item">
              <div class="info-item-title">报警类型</div>
              <div class="info-item-content">{{ formatWarnType(detailsData.warnType) }}</div>
            </div>
            <div class="info-item">
              <div class="info-item-title">结束时间</div>
              <div class="info-item-content">{{ detailsData.flag == 1 ? "报警中..." : detailsData.timeE }}</div>
            </div>
            <div class="info-item">
              <div class="info-item-title">报警级别</div>
              <div class="info-item-content" :style="{ color: detailsData.level == 1 ? '#1890FF' : detailsData.level == 2 ? '#FFB134' : '#D12A36' }">{{ formatLevel(detailsData.level) }}</div>
            </div>
            <div class="info-item">
              <div class="info-item-title">监测指标</div>
              <div class="info-item-content">{{ detailsData.quotaName }}</div>
            </div>
            <template v-for="(item, index) in detailsData.dataSource">
              <div class="info-item">
                <div class="info-item-title">
                  数据源
                  <span v-if="detailsData.dataSource.length > 1">({{ index + 1 }})</span>
                </div>
                <div class="info-item-content">{{ item.dataName }}</div>
              </div>
              <div class="info-item">
                <div class="info-item-title">阈值</div>
                <div class="info-item-content">{{ item.threshold }}</div>
              </div>
              <div class="info-item">
                <div class="info-item-title">最大报警值</div>
                <div class="info-item-content">{{ item.maxValue }}</div>
              </div>
              <div class="info-item">
                <div class="info-item-title">持续次数</div>
                <div class="info-item-content">{{ item.count }}</div>
              </div>
            </template>
            <div class="info-item">
              <div class="info-item-title">检查建议</div>
              <div class="info-item-content2">{{ detailsData.suggest }}</div>
            </div>
          </div>
          <div v-show="showCxCharts2 && operateType != 'multiple'" style="margin-top: 20px">数据源(1)</div>
          <div v-show="showCxCharts1 && operateType != 'multiple'" id="cx-echarts" class="cxEcharts"></div>
          <div v-show="showCxCharts2 && operateType != 'multiple'" style="margin-top: 20px">数据源(2)</div>
          <div v-show="showCxCharts2 && operateType != 'multiple'" id="cx-echarts2" class="cxEcharts"></div>
          <div class="content" v-show="operateType == 'multiple'">
            <el-table :data="dialogTableData" :row-class-name="tableRowClassName1" :header-row-style="headerRowStyle">
              <el-table-column prop="timeS" label="超限时间" width="200px"></el-table-column>
              <el-table-column prop="code" label="测点编号"></el-table-column>
              <el-table-column prop="typeName" label="监测指标"></el-table-column>
              <el-table-column width="30"></el-table-column>
            </el-table>
          </div>
        </div>
        <el-divider class="my-divider"></el-divider>

        <div class="handle-detail-wrapper">
          <div class="title">
            <el-divider class="my-shu-divider" direction="vertical"></el-divider>
            <span class="title-name">处理情况</span>
          </div>
          <div class="picture-wrapper">
            <div class="label-name">故障照片</div>
            <div class="upload-picture">
              <el-upload
                v-show="operateType != 'see' && imgList.length < 3"
                class="upload-demo"
                :headers="{ token }"
                :on-success="onSuccess"
                :before-upload="beforeUpload"
                ref="upload"
                accept=".jpg, .png"
                :action="baseURL + 'sys/sysImages/save/warn'"
                :show-file-list="false"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <div :class="operateType == 'see' || imgList.length == 3 ? 'show-img-wrapper' : 'show-img-wrapper upload-img-wrapper'" v-show="imgList.length">
                <div v-for="(item, index) in imgList" class="common-img-div">
                  <img :key="index" :src="item.src" alt="" />
                  <img v-show="operateType != 'see'" class="delete-img" src="@/assets/images/report/winClose.png" alt="" @click="handleDeleteImg(item)" />
                </div>
              </div>
            </div>
          </div>
          <el-form :model="handleForm" ref="handleForm" :rules="rules" class="my-form" label-width="80px">
            <el-form-item label="处理措施" prop="measure">
              <el-input type="textarea" v-model="handleForm.measure" class="high-textarea" :disabled="operateType == 'see'"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input type="textarea" v-model="handleForm.remark" :disabled="operateType == 'see'"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn-footer" v-show="operateType != 'see'">
          <el-button class="save-btn" @click="handleSave">保存</el-button>
          <el-button class="cancel-btn" @click="handleDialogClose">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="数据分析报警录入" :visible.sync="isShowDataOverrun" class="my-dialog" top="15vh">
      <div class="operate-wrapper">
        <div class="info-wrapper">
          <el-form :model="dataOverrunForm" ref="dataOverrunForm" class="my-form" label-width="80px">
            <el-form-item label="监测内容" prop="measure">
              <el-select v-model="dataOverrunForm.warnType" placeholder="请选择">
                <el-option v-for="item in overrunContentList" :key="item.name" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="报警级别" prop="measure">
              <el-radio v-for="item in dataWarningLevelOptions" :key="item.id" v-model="dataOverrunForm.level" :label="item.id">{{ item.name }}</el-radio>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn-footer">
          <el-button class="save-btn" @click="handleDataOverrunSave">保存</el-button>
          <el-button class="cancel-btn" @click="isShowDataOverrun = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import echarts from "echarts";
import CommonPagination from "@/components/Pagination";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { cxChartOption } from "./echartsOptions.js";
// import { tableHeaderData } from "../../views/data.js"
export default {
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),
      // projectId: 10008,
      token: window.sessionStorage.getItem("token"),
      isAdmin: sessionStorage.getItem("isAdmin"),
      selectTables: [],
      unit: "mm",
      total: 0,
      currentPage: 1,
      pageSize: 8,
      // 表格数据
      tableData: [],
      // 是否显示表头配置框
      isShowHeaderList: false,
      // 选中的表头字段
      checkedTbHeader: [],
      // 默认选中
      defaultData: ["预警时间", "预警级别", "测点编号"],
      // 自定义表头字段
      tableHeaderData: [],
      headerDetails: [],
      // 搜索表单
      operateForm: {
        level: "",
        warnType: "",
        code: "",
        status: "",
        startTime: "",
        endTime: "",
      },
      // 预警类型
      warningTypeOptions: [
        {
          id: "",
          name: "全部",
        },
        {
          id: 1,
          name: "环境报警",
        },
        {
          id: 2,
          name: "作用报警",
        },
        {
          id: 3,
          name: "结构响应报警",
        },
        {
          id: 4,
          name: "结构变化报警",
        },
        {
          id: 5,
          name: "监测数据分析结构报警",
        },
      ],
      // 预警等级
      warningLevelOptions: [
        {
          id: "",
          name: "全部",
        },
        {
          id: 1,
          name: "一级超限",
        },
        {
          id: 2,
          name: "二级超限",
        },
        {
          id: 3,
          name: "三级超限",
        },
      ],
      // 处理状态
      statusOptions: [
        {
          id: "",
          name: "全部",
        },
        {
          id: 0,
          name: "未处理",
        },
        {
          id: 1,
          name: "已处理",
        },
      ],
      // 监测类型
      typeRelOptions: [
        {
          id: 1,
          name: "自动化监测",
        },
        {
          id: 2,
          name: "人工监测",
        },
      ],
      // 操作类型 查看/处理
      operateType: "",
      // 弹窗
      dialogVisible: false,
      // 详情
      detailsData: {},
      // 处理表单
      handleForm: {},
      // 表单验证
      rules: {
        measure: [{ required: true, message: "请输入处理措施", trigger: "blur" }],
      },
      // 批量处理 表格数据
      dialogTableData: [],
      // 当前选中的行
      currentRowId: "",
      // 上传的故障图片
      imgList: [],
      // 当前点击的排序列
      currentSort: {
        currentSortId: "", // id
        currentSortOrder: "", // 正序 倒叙
        value: "", // prop
      },
      isShowDataOverrun: false,
      dataOverrunForm: {
        level: 1,
        warnType: undefined,
      },
      dataWarningTypeOptions: [
        {
          id: 1,
          name: "环境报警",
        },
        {
          id: 2,
          name: "作用报警",
        },
        {
          id: 3,
          name: "结构响应报警",
        },
        {
          id: 4,
          name: "结构变化报警",
        },
        {
          id: 5,
          name: "监测数据分析结构报警",
        },
      ],
      // 预警等级
      dataWarningLevelOptions: [
        {
          id: 1,
          name: "一级超限",
        },
        {
          id: 2,
          name: "二级超限",
        },
        {
          id: 3,
          name: "三级超限",
        },
      ],
      showCxCharts1: false,
      showCxCharts2: false,
      headerRowStyle: {
        background: '#0B2D5E', 
        color: '#fff',
      },
      tableHeight:0
    };
  },
  created() {
    this.getNowDate();
    this.getTableHeadData();
    this.getSize();
    window.addEventListener("resize", () =>  { 
      this.getSize();
    }, false);
  },
  mounted() {
    this.getTableData();
    this.getDataOverrunContent();
    console.log("是否是超级管理员", this.isAdmin);
  },
  methods: {
    handleDataOverrunSave() {
      const params = {
        projectId: this.projectId,
        level: this.dataOverrunForm.level,
        contentId: this.dataOverrunForm.warnType,
      };
      this.$axios.post(`${this.baseURL}data/overrun/analyse/content`, qs.stringify(params)).then((res) => {
        if (res.data.status == 200) {
          this.$message.success("操作成功");
          this.isShowDataOverrun = false;
        }
      });
    },
    getDataOverrunContent() {
      this.$axios.get(`${this.baseURL}data/overrun/analyse/content/${this.projectId}`).then((res) => {
        if (res.data.status == 200) {
          this.overrunContentList = res.data.data;
          if(res.data.data.length){
            this.dataOverrunForm.warnType = res.data.data[0].id
          }
        }
      });
    },
    showDataoverrun() {
      this.isShowDataOverrun = true;
    },
    // 设置默认时间 当前时间、向前推30天
    getNowDate() {
      let now = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      this.$set(this.operateForm, "endTime", now);
      let monthBefore = moment(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).format("YYYY-MM-DD HH:mm:ss");
      this.$set(this.operateForm, "startTime", monthBefore);
      console.log("当前的时间", now, monthBefore);
    },
    // 是否能选择
    checkIsSelectable(row, index) {
      if (row.status == 1) {
        return false;
      } else {
        return true;
      }
    },
    // 获取表格表头数据
    getTableHeadData() {
      this.$axios.get(`${this.baseURL}data/overrun/operate/${this.projectId}`).then((res) => {
        if (res.data.status == 200) {
          console.log("获取表格表头数据------", res);
          let detail = res.data.data;
          let data = [];
          if (detail.length) {
            this.checkedTbHeader = [];
            detail.forEach((item) => {
              if (item.status == 1) {
                this.checkedTbHeader.push(item.name);
                data.push(item);
              }
              if (item.name == "测点编号" || item.name == "预警时间" || item.name == "预警级别") {
                item.disabled = true;
              }
            });
            this.tableHeaderData = detail;
            this.headerDetails = data;
          } else {
            this.checkedTbHeader = this.defaultData;
          }
        }
      });
    },
    // 获取表格数据
    getTableData() {
      let path = `pageNum=${this.currentPage}&pageSize=${this.pageSize}&projectId=${this.projectId}
      &code=${this.operateForm.code}&startTime=${this.operateForm.startTime}&endTime=${this.operateForm.endTime}
            &level=${this.operateForm.level}&status=${this.operateForm.status}&warnType=${this.operateForm.warnType}`;
      if (this.currentSort.currentSortId) {
        path += `&orders[0].id=${this.currentSort.currentSortId}`;
      }
      if (this.currentSort.currentSortOrder) {
        path += `&orders[0].orders=${this.currentSort.currentSortOrder}`;
      }
      this.$axios.get(`${this.baseURL}data/overrun/page?${path}`).then((res) => {
        if (res.data.status == 200) {
          console.log("获取表格数据", res);
          let detail = res.data.data;
          this.tableData = detail.records;
          this.total = detail.total;

          var options = {
            grid: {
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            },
            tooltip: {
              position: "bottom",
            },
            radar: {
              radius: "55%",
              indicator: [],
              splitLine: {
                lineStyle: {
                  color: ["#A9C5D8"], // 可以设置为单一颜色或数组，表示分隔线的颜色
                },
              },
              splitArea: {
                show: false, // 设置为 false，表示不显示背景区域
              },
            },
            series: [
              {
                type: "radar",
                // 数据
                data: [
                  {
                    value: [],
                    name: "",
                    lineStyle: {
                      color: "#1AC7FD", // 数据线的颜色
                      width: 2,
                    },
                    itemStyle: {
                      color: "rgb(0,0,0)",
                      borderColor: "#1AC7FD",
                      borderWidth: 2,
                    },
                    symbolSize: 8,
                  },
                ],
                areaStyle: {
                  color: "rgba(0, 148, 255, 0.21)", // 设置折线区域颜色
                },
              },
            ],
            
          };
          var chartContainer = document.getElementById("cx-echarts");
          var myChart = echarts.init(chartContainer);
          myChart.setOption(option);
        }
      });
    },
    // 查询
    handleSearch() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 重置
    handleReset() {
      (this.operateForm = {
        level: "",
        warnType: "",
        code: "",
        status: "",
      }),
        this.getNowDate();
      this.getTableData();
    },
    // 复选框
    handleSelectionChange(val) {
      // console.log(val)
      this.selectTables = val;
    },
    // 获取详情
    getDetails(id) {
      this.$axios.get(`${this.baseURL}data/overrun/info/${id}`).then((res) => {
        if (res.data.status == 200) {
          this.detailsData = res.data.data;
          this.handleForm = res.data.data;
          this.imgList = [];
          if (this.operateType == "see") {
            let ids = res.data.data.imageId.split(",");
            ids.forEach((eve) => {
              this.imgList.push({
                id: res.data.data.id,
                src: `${this.baseURL}sys/sysImages/img/${eve}`,
              });
            });
          }
          if (this.detailsData.dataSource.length) {
            this.getDataOverrunInfoDatas(this.detailsData.dataSource[0].dataOverrunId);
          }
        }
      });
    },
    getDataOverrunInfoDatas(dataOverrunId) {
      var chartContainer = document.getElementById("cx-echarts");
      var chartContainer2 = document.getElementById("cx-echarts2");
      var myChart = echarts.init(chartContainer);
      var myChart2 = echarts.init(chartContainer2);
      var params = {
        dataOverrunId,
      };
      this.$axios.get(`${this.baseURL}data/overrun/info/datas`, { params }).then((res) => {
        if (res.data.status == 200) {
          var resData = res.data.data;
          var times = [];
          var datas = [];
          var options = "";
          resData.forEach((resDataItem, index) => {
            options = JSON.parse(JSON.stringify(cxChartOption));
            var datalList = resDataItem.datas
            options.tooltip = {
              trigger: "axis",
              formatter : (params)=>{
                return `${datalList[params[0].dataIndex].time}<br>报警值: ${params[0].data}`;
              }
            }
            var dataItem = resDataItem;
            if (dataItem) {
              dataItem.datas.forEach((item) => {
                times.push(moment(item.time).format("HH:mm"));
                datas.push(item.data);
                options.yAxis.min = Math.floor(item.data < options.yAxis.min ? item.data : options.yAxis.min);
                options.yAxis.max = Math.ceil(item.data > options.yAxis.max ? item.data : options.yAxis.max);
              });
              options.xAxis.data = times;
              options.xAxis.axisLabel = {
                interval: Math.ceil(times.length / 6),
                showMinLabel: true,
                showMaxLabel: true,
              };

              options.series[0].data = datas;

              var thresholdMinusArr = dataItem.thresholdMinus.split(",");
              var thresholdPlusArr = dataItem.thresholdPlus.split(",");
              var thresholdLine = dataItem.thresholdLine;

              if (thresholdLine.includes("1")) {
                var thresholdMinus = -Number(thresholdMinusArr[0]);
                var thresholdPlus = Number(thresholdPlusArr[0]);
                options.yAxis.min = Math.floor(thresholdMinus < options.yAxis.min ? thresholdMinus : options.yAxis.min);
                options.yAxis.max = Math.ceil(thresholdPlus > options.yAxis.max ? thresholdPlus : options.yAxis.max);
                options.series[0].markLine.data.push(
                  {
                    yAxis: thresholdMinus,
                    lineStyle: {
                      color: "#1890FF",
                      type: "solid",
                    },
                    label: {
                      position: "end",
                      formatter: "一级超限",
                    },
                  },
                  {
                    yAxis: thresholdPlus,
                    lineStyle: {
                      color: "#1890FF",
                      type: "solid",
                    },
                    label: {
                      position: "end",
                      formatter: "一级超限",
                    },
                  }
                );
              }
              if (thresholdLine.includes("2")) {
                var thresholdMinus = -thresholdMinusArr[1];
                var thresholdPlus = thresholdPlusArr[1];
                options.yAxis.min = Math.floor(thresholdMinus < options.yAxis.min ? thresholdMinus : options.yAxis.min);
                options.yAxis.max = Math.ceil(thresholdPlus > options.yAxis.max ? thresholdPlus : options.yAxis.max);
                options.series[0].markLine.data.push(
                  {
                    yAxis: thresholdMinus,
                    lineStyle: {
                      color: "#FFB134",
                      type: "solid",
                    },
                    label: {
                      position: "end",
                      formatter: "二级超限",
                    },
                  },
                  {
                    yAxis: thresholdPlus,
                    lineStyle: {
                      color: "#FFB134",
                      type: "solid",
                    },
                    label: {
                      position: "end",
                      formatter: "二级超限",
                    },
                  }
                );
              }
              if (thresholdLine.includes("3")) {
                var thresholdMinus = -thresholdMinusArr[2];
                var thresholdPlus = thresholdPlusArr[2];
                options.yAxis.min = Math.floor(thresholdMinus < options.yAxis.min ? thresholdMinus : options.yAxis.min);
                options.yAxis.max = Math.ceil(thresholdPlus > options.yAxis.max ? thresholdPlus : options.yAxis.max);
                options.series[0].markLine.data.push(
                  {
                    yAxis: thresholdMinus,
                    lineStyle: {
                      color: "#D12A36",
                      type: "solid",
                    },
                    label: {
                      position: "end",
                      formatter: "三级超限",
                    },
                  },
                  {
                    yAxis: thresholdPlus,
                    lineStyle: {
                      color: "#D12A36",
                      type: "solid",
                    },
                    label: {
                      position: "end",
                      formatter: "三级超限",
                    },
                  }
                );
              }
            }
            if (index == 0) {
              this.showCxCharts1 = true;
              this.$nextTick(() => {
                myChart.setOption(options);
                myChart.resize();
              });
            }
            if (index == 1) {
              this.showCxCharts2 = true;
              this.$nextTick(() => {
                myChart2.setOption(options);
                myChart2.resize();
              });
            }
          });
        }
      });
    },
    // 查看
    handleSee(row) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.operateType = "see";
      this.dialogVisible = true;
      this.getDetails(row.id);
    },
    // 处理
    handleOperate(row) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.operateType = "handle";
      this.dialogVisible = true;
      this.showCxCharts1 =false
      this.showCxCharts2 =false
      this.getDetails(row.id);
      this.currentRowId = row.id;
    },
    // 批量处理
    handleMultiple() {
      // let select = this.$refs.myTable.selection
      // console.log('获取选中的表格行', select)
      // if (!select.length) {
      //     this.$message.warning('请先选择需要批量处理的数据')
      //     return
      // }

      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.operateType = "multiple";
      this.dialogTableData = this.selectTables;
      this.imgList = [];
      this.handleForm = {};
      this.dialogVisible = true;
    },
    // 删除
    handleDelete(row) {
      this.$confirm("确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm",
      }).then(() => {
        this.$axios.delete(`${this.baseURL}data/overrun/${row.id}`).then((res) => {
          if (res.status == 200) {
            this.$message.success("删除成功");
            this.getTableData();
          } else {
            this.$message.error(res.errMsg);
          }
        });
      });
    },
    // 保存
    handleSave() {
      this.$refs.handleForm.validate((valid) => {
        if (valid) {
          let data = {
            projectId: this.projectId,
            measure: this.handleForm.measure,
            remark: this.handleForm.remark,
          };
          let imgIds = [];
          if (this.imgList.length) {
            this.imgList.forEach((item) => {
              imgIds.push(item.id);
            });
            data.imageId = String(imgIds);
          }
          let overrunId = [];
          if (this.operateType == "multiple") {
            this.dialogTableData.forEach((eve) => {
              overrunId.push(eve.id);
            });
            data.overrunId = overrunId.join(",");
          } else {
            data.overrunId = this.currentRowId;
          }
          this.$axios.get(`${this.baseURL}data/overrun/add`, { params: data }).then((res) => {
            if (res.status == 200) {
              this.dialogVisible = false;
              this.$message.success("处理成功");
              this.getTableData();
            } else {
              this.$message.error(res.errMsg);
            }
          });
        }
      });
    },
    // 上传成功
    onSuccess(res, file, fileList) {
      console.log("上传故障图片------", res);
      if (res.status == "200" && this.imgList.length < 3) {
        this.imgList.push({
          id: res.data,
          src: `${this.baseURL}sys/sysImages/img/${res.data}`,
        });
      }
    },
    // 上传之前
    beforeUpload(file) {
      // this.operateType = 'sysLogo'
      // console.log(file.type)
      const fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isCorrectType = fileType === "jpg" || fileType === "png";
      if (!isCorrectType) {
        this.$message.error("文件格式不正确，请上传.jpg/.png文件");
        return;
      }
      return isCorrectType;
    },
    // 移除图片
    handleDeleteImg(item) {
      console.log(item.id);
      this.imgList = this.imgList.filter((eve) => {
        return eve.id != item.id;
      });
      console.log(this.imgList);
    },
    // 表格头
    renderHeader(h, { column }) {
      // console.log("h", h, "column", column);
      let str = "";
      if (column.property === "opt") {
        str = ' <i class="el-icon-s-operation" style="font-size: 18px"></i>';
      }
      if (column.property === "timeO" || column.property === "typeName" || column.property === "level" || column.property === "typeRel" || column.property === "status") {
        str = ' <i class="el-icon-sort" style="font-size: 18px;color: #CCDAFF"></i>';
      }
      if (column.property == this.currentSort.value) {
        str = ' <i class="el-icon-sort" style="font-size: 18px;color: #2772F0"></i>';
      }
      return h("div", [
        h("span", {
          domProps: {
            innerHTML: column.label,
          },
          style: {
            color: "#d8d8d8",
            fontSize: "16px",
            // marginRight: "10px"
          },
        }),
        h("span", {
          domProps: {
            innerHTML: str,
          },
          style: {
            color: "#d8d8d8",
            fontSize: "14px",
            // marginRight: "10px"
          },
        }),
      ]);
    },
    // 点击自定义表头字段icon
    headerClick(c, e) {
      if (c.property == "opt") {
        this.getTableHeadData();
        this.isShowHeaderList = !this.isShowHeaderList;
      }
      if (c.property == "timeO" || c.property == "typeName" || c.property == "level" || c.property == "typeRel" || c.property == "status") {
        this.headerDetails.forEach((item) => {
          if (item.value == c.property) {
            this.currentSort.currentSortId = item.id;
            this.currentSort.currentSortOrder == ""
              ? (this.currentSort.currentSortOrder = 1)
              : this.currentSort.currentSortOrder == 1
              ? (this.currentSort.currentSortOrder = -1)
              : (this.currentSort.currentSortOrder = 1);
            this.currentSort.value = item.value;
          }
        });
        this.currentPage = 1;
        this.getTableData();
      }
    },
    // 自定义表头 确定
    handleConfirm() {
      let data = [];
      this.tableHeaderData.forEach((item) => {
        if (this.checkedTbHeader.indexOf(item.name) >= 0) {
          item.status = 1;
        } else {
          item.status = 0;
        }
        data.push(item);
      });
      console.log("发送的", data);
      this.$axios.post(`${this.baseURL}data/overrun/save/operate/${this.projectId}`, data).then((res) => {
        if (res.data.status == 200) {
          this.headerDetails = res.data.data;
          this.isShowHeaderList = false;
          this.getTableHeadData();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 表格条纹颜色
    tableRowClassName({ row, rowIndex }) {
      if (row.flag == 1 && row.status == 0) {
        return "waring-row";
      }
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    // 表格条纹颜色
    tableRowClassName1({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui2";
      } else {
        return "lv2";
      }
    },
    // 切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    // 关闭弹窗
    handleDialogClose() {
      this.dialogVisible = false;
      this.imgList = [];
      this.handleForm = {};
      this.$refs.handleForm.resetFields();
    },
    // 格式化 预警级别
    formatLevel(level) {
      let str = "";
      this.warningLevelOptions.forEach((item) => {
        if (item.id == level) {
          str = item.name;
        }
      });
      return str;
    },
    // 格式化 监测类型
    formatterType(num) {
      let str = "";
      this.typeRelOptions.forEach((item) => {
        if (item.id == num) {
          str = item.name;
        }
      });
      return str;
    },
    // 格式化 处理状态
    formatStauts(status) {
      let str = "";
      this.statusOptions.forEach((item) => {
        if (item.id == status) {
          str = item.name;
        }
      });
      return str;
    },
    formatWarnType(type) {
      let str = "";
      this.warningTypeOptions.forEach((item) => {
        if (item.id == type) {
          str = item.name;
        }
      });
      return str;
    },
    getSize(){
        this.tableHeight = window.innerHeight - 330
    }
  },
  components: {
    CommonPagination,
  },
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  justify-content: space-between;
  line-height: 40px;
  margin-top: 20px;
  .search {
    .el-form--inline .el-form-item {
      margin-bottom: 0;
    }
    span.line {
      margin-right: 10px;
    }
    ::v-deep .el-input .el-input__inner {
      width: 130px;
      height: 32px;
      line-height: 32px;
    }
    ::v-deep .el-select .el-input .el-input__inner {
      width: 110px;
      height: 32px;
      line-height: 32px;
    }
    ::v-deep .el-input__suffix {
      height: 32px;
      line-height: 32px;
      top: 4px;
    }
    ::v-deep .my-type .el-input .el-input__inner {
      // width: 200px;
    }
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      // width: 155px !important;
    }
    ::v-deep .el-date-editor input.el-input__inner {
      width: 100%;
      padding-right: 5px;
    }
    .reset-btn {
      background: none;
      border: 1px solid #2667db;
      margin-left: 20px;
    }
  }
  .btn-right-wrapper {
    .el-button {
      padding: 0 17px;
    }
  }
}
.table-wrapper {
  margin-top: 25px;
  position: relative;
  .see-btn,
  .handle-btn,
  .delete-btn {
    cursor: pointer;
  }
  .el-table .el-table__body-wrapper tbody tr {
    span.delete-btn {
      margin-left: 16px;
    }
    span.red-level {
      color: #d12a36 !important;
    }
    span.yellow-level {
      color: #ffb134 !important;
    }
    span.blue-level {
      color: #1890ff !important;
    }
    span.green-level {
      color: #22a358 !important;
    }
  }

  .tbopt {
    width: 160px;
    position: absolute;
    right: 16px;
    top: 40px;
    z-index: 99;
    background-image: radial-gradient(circle at 47% 7.4%, #0c104d 0%, #0b2d5a 132%);
    box-shadow: 0 2px 7px 0 rgba(6, 25, 73, 0.53);
    border-radius: 8px;
    line-height: 2;
    padding: 10px 0 20px 0;
    border: 1px solid #204370;
    .tbopt-item {
      padding-left: 16px;
      ::v-deep span.el-checkbox__label {
        font-size: 14px;
        color: #d8d8d8;
      }
    }
    .btn-click {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      border-top: 1px solid #2a3f71;
      button.el-button {
        font-size: 12px;
        padding: 5px 18px;
        margin-top: 20px;
      }
      .cancel-btn {
        background: none;
        border: 1px solid #2667db;
      }
    }
  }
}
::v-deep .my-form {
  .el-form-item__label {
    text-align: left;
    width: auto;
  }
}
::v-deep .my-form .el-input__suffix {
  top: 2px;
}
::v-deep .my-form .el-radio__label {
  color: #fff;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #409eff;
}
::v-deep .hui1 {
  background: #091d46 !important;
}
::v-deep .lv1 {
  background: #0c265a !important;
}
::v-deep .hui2 {
  background: #0A2E63 !important;
  height: 40px;
}
::v-deep .lv2 {
  background: #0D336B !important;
  height: 40px;

}
::v-deep .el-button.is-disabled {
  border: 1px solid #314c98;
  background: none;
  color: #314c98;
}
::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  font-size: 14px;
  text-align: center;
}
.common-page.pageMain {
  margin-top: 15px;
}
::v-deep .my-dialog .el-dialog {
  width: 610px !important;
  border: none;
  background-image: linear-gradient(0deg, #0a173d 0%, #124286 100%);
  .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.86);
    }
  }
  .el-dialog__body {
    padding: 0 20px 40px 20px;
    color: #fff;
    .operate-wrapper {
      .info-wrapper {
        padding-top: 20px;
        .info-ul {
          line-height: 3.5;
          font-size: 12px;
          .label-name {
            width: 100px;
            display: inline-block;
          }
        }
        .info-table {
          display: flex;
          flex-wrap: wrap;
          border: 1px solid #3b68a9;
          border-top: 0;
          border-right: 0;
          .info-item {
            display: flex;

            .info-item-title {
              padding: 11px 0;
              width: 97px;
              border-top: 1px solid #3b68a9;
              border-right: 1px solid #3b68a9;
              text-align: center;
            }
            .info-item-content {
              width: 187px;
              padding: 11px 0;
              font-size: 12px;
              text-align: center;
              border-top: 1px solid #3b68a9;
              border-right: 1px solid #3b68a9;
            }
            .info-item-content2 {
              width: 471px;
              padding: 11px 0;
              text-align: left;
              padding-left: 50px;
              border-top: 1px solid #3b68a9;
              border-right: 1px solid #3b68a9;
              font-size: 12px;
            }
            // .info-item-content:nth-child(2n) {
            //   border-right: 0;
            // }
          }
        }
      }
      .handle-detail-wrapper {
        .picture-wrapper {
          padding-left: 16px;
          margin-bottom: 15px;
          .label-name {
            font-size: 12px;
          }
          .upload-picture {
            display: flex;
            height: 80px;
            line-height: 80px;
            padding-left: 20px;
            .upload-demo {
              display: inline-block;
              .el-icon-plus {
                font-size: 28px;
                font-weight: bold;
                color: #2a65da;
              }
            }
            .show-img-wrapper {
              height: 100%;
              display: flex;
              margin-left: 60px;
              margin-bottom: 10px;
              img {
                width: 100px;
                height: 80px;
                margin-right: 16px;
              }
            }
            .upload-img-wrapper {
              margin-left: 30px;
            }
            .common-img-div {
              position: relative;
              img.delete-img {
                width: 14px;
                height: 14px;
                position: absolute;
                top: -5px;
                right: -5px;
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
        .my-form {
          margin-top: 10px;
          padding-left: 16px;
          .el-textarea {
            width: 80%;
            .el-textarea__inner {
              background: none;
              border: 1px solid #0e43a3;
              color: rgba(255, 255, 255, 0.86);
            }
          }
          .high-textarea .el-textarea__inner {
            min-height: 77px !important;
          }
        }
      }
      .title {
        margin-bottom: 15px;
        .my-shu-divider {
          width: 2px;
          background: #2667db;
          margin: 0 !important;
          margin-right: 13px !important;
        }
      }
      .my-divider {
        margin: 15px 0;
        background: #7ca9df99;
      }
      .el-form-item__label {
        font-size: 12px !important;
      }
      .el-input.is-disabled {
        width: 100% !important;
        .el-input__inner {
          font-size: 12px !important;
          background: rgba(35, 51, 97, 0.7) !important;
          border: 1px solid #2c487b;
        }
      }
      .el-input .el-input__inner {
        background: #0c265a;
        border: 1px solid #0e43a3;
      }
      .btn-footer {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        text-align: center;
        .el-button {
          width: 88px;
          height: 32px;
          line-height: 32px;
          padding: 0;
          margin: 0;
          font-size: 12px;
          font-weight: 500;
        }
        .save-btn {
          margin-right: 16px;
        }
        .cancel-btn {
          margin-left: 20px;
          background: none;
          border: 1px solid #2667db;
        }
      }
    }
  }
}
::v-deep .el-dialog div.el-table th {
  height: 40px !important;
  background-color: transparent !important;
  font-size: 12px !important;
}
::v-deep .el-dialog div.el-table td {
  font-size: 12px !important;
}
::v-deep .el-dialog .el-table__body-wrapper {
  max-height: 255px;
  overflow-y: scroll;
}
::v-deep .el-checkbox .el-checkbox__inner {
  background: #2772f0 !important;
  opacity: 0.2;
  border-color: transparent;
}
::v-deep .el-checkbox.is-checked.is-disabled .el-checkbox__inner {
  background: #777 !important;
  opacity: 1;
  border-color: transparent;
}
::v-deep .el-checkbox.is-checked .el-checkbox__inner {
  background: #2772f0 !important;
  opacity: 1;
  border-color: transparent;
}
/* ::v-deep div.el-table .el-table__body tr:hover td,
    ::v-deep div.el-table .el-table__body tr:hover span {
        font-weight: normal !important;
        color: #fff;
    }   */
::v-deep .waring-row {
  background:  #ff626226;
  // background: linear-gradient(to bottom, #ff626226, #ff626226 99%, #f8b0b0);
  // background: linear-gradient(to bottom, #ff626226 0%, #ff626226 calc(100% - 1px), #f8b0b0 100%);
}
::v-deep .waring-row td{
  border-bottom: 1px solid rgba(248, 176, 176, 0.4) !important;
  // background: linear-gradient(to bottom, #ff626226, #ff626226 99%, #f8b0b0);
  // background: linear-gradient(to bottom, #ff626226 0%, #ff626226 calc(100% - 1px), #f8b0b0 100%);
}
.cxEcharts {
  width: 100%;
  height: 180px;
}
::v-deep .el-dialog__header{
  background-image: linear-gradient(94deg, #124183 0%, #123682 100%);
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #7ca9df99;;
}
::v-deep .el-dialog__headerbtn {
  width: 24px;
  height: 24px;
  background: rgba(204, 218, 255, 0.2);
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  top: 14px;
} 
::v-deep .el-icon-close:before {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
}

::v-deep .el-dialog__body{
  background-image: linear-gradient(0deg, #0A173D 0%, #124286 100%);
  border-radius: 4px;
}


::v-deep .el-dialog__header{
  background-image: linear-gradient(94deg, #124183 0%, #123682 100%);
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #7ca9df99 !important;
}
::v-deep .el-dialog__headerbtn {
  width: 24px;
  height: 24px;
  background: rgba(204, 218, 255, 0.2);
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  top: 14px;
} 
::v-deep .el-icon-close:before {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
}

::v-deep .el-dialog__body{
  background-image: linear-gradient(0deg, #0A173D 0%, #124286 100%);
  border-radius: 4px;
  border:none;
}


::v-deep .el-radio__input.is-checked + .el-radio__label{
  color: #ffffff;
}

</style>
