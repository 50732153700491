export const cxChartOption = {
    grid: {
        top: 25,
        left: 40,
        bottom: 20,
        right: 65,
    },
    xAxis: {
        type: "category",
        data: [],
        axisLine: {
            lineStyle: {
                color: "rgba(255, 255, 255, 0.38)",
            },
        },
        axisTick: {
            show: false,
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: "#A7C8FC",
            },
        },
    },
    yAxis: {
        type: "value",
        min: 0,
        max: 0,
        axisLine: {
            show: false,
        },
        axisTick: {
            show: false,
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: "#A7C8FC",
            },
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: "rgba(255, 255, 255, 0.38)",
                type: "dashed",
            },
        },
    },
    series: [
        {
            type: "line",
            lineStyle: {
                color: "#1AA5FF",
            },
            itemStyle: {
                color: "#FFFFFF", // 白色
            },
            data: [],
            markLine: {
                symbol: "none",
                silent: true,
                animation: false,
                data: [],
            },
        },
    ],
};